
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';

export default {
  name: 'Folder',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar, IonSegment, IonSegmentButton, IonLabel
  },
  data () {
    return {
        currentSegment: 0
    }
  },
  methods: {
    segmentChanged(ev: CustomEvent) {
      this.currentSegment = ev.detail.value;
    },
  }
}
