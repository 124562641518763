<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Test Title</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-segment @ionChange="segmentChanged($event)" value="0">
          <ion-segment-button value="0">
            <ion-label>Details</ion-label>
          </ion-segment-button>
          <ion-segment-button value="1">
            <ion-label>Preview</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true" v-if="currentSegment == 0">
      <div class="container">
        <strong class="capitalize">Details</strong>
        <p>Explore <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">UI Components</a></p>
      </div>
    </ion-content>

    <ion-content :fullscreen="true" v-if="currentSegment == 1">
      <div class="container">
        <strong class="capitalize">Preview</strong>
        <p>Explore <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">UI Components</a></p>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';

export default {
  name: 'Folder',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar, IonSegment, IonSegmentButton, IonLabel
  },
  data () {
    return {
        currentSegment: 0
    }
  },
  methods: {
    segmentChanged(ev: CustomEvent) {
      this.currentSegment = ev.detail.value;
    },
  }
}
</script>

<style scoped>
.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}
</style>